import { Grid, Button } from "@material-ui/core";
import React, { useState, useContext, useEffect } from "react";
import { MainContext } from "../../../context/MainContext";
import useStyles from "../../../components/styles/styles";
import Input from "../../../components/utils/Input";
import Api from "../../../constant/Api";
import { useHistory } from "react-router-dom";
import { postRequest } from "../../../constant/ApiService";
import { toast } from "react-toastify";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { getRequest } from "../../../constant/ApiService";
import Select from "react-select";
import { options } from "toastr";

const SocialMediaOrder = () => {
  const classes = useStyles();
  const history = useHistory();
  const [NewContent, setNewContent] = useState({
    sourceCityId: 0,
    origin: "",
    destinationCityId: 0,
    destination: "",
    packingId: 0,
    vehicleClassId: 0,
    title: "",
    weight: 0,
    senderPhoneNumber: "",
    requestPrice: 0,
    description: "",
  });

  let {
    city,
    setCity,
    cityOption,
    carrier,
    setCarrier,
    handleTruckType,
    newOrder,
    setNewOrder,
  } = useContext(MainContext);

  const cityOption3 = item => {
    console.log(item, "cityOption3")
    getRequest(`${Api.GetCity}?CityName=${item}`).then(res => {
      const handleOptions =
        res &&
        res.data.lists.map(item => {
          return {
            value: item.id,
            label: item.cityName,
          };
        });

      setCity(handleOptions);
    });
  };

  useEffect(() => {
    handleTruckType();
  }, []);

  useEffect(() => {
    cityOption();
    setCarrier({ ...carrier, truckTypeValue: "" });
    setCarrier({ ...carrier, carrierTypeValue: "" });
  }, []);

  const addNewContent = () => {

    if (!NewContent.sourceCityId) {
      toast.error("مشخصات بار را وارد کنید");
      return;
    }

    let body = {
      sourceCityId: NewContent.sourceCityId,
      destinationCityId: NewContent.destinationCityId,
      packingId: 7,
      vehicleClassId: NewContent.vehicleClassId,
      title: NewContent.title,
      weight: NewContent.weight,
      senderPhoneNumber: NewContent.senderPhoneNumber,
      requestPrice: NewContent.requestPrice,
      description: NewContent.description,

    };

    console.log(body)
    postRequest(Api.CreateNewCargo, body).then(res => {
      if (res && res.succeeded && res.succeeded == true) {
        toast.success(res.message);
        //history.push("/app/comments");
      }
    });
  };
  return (
    <Grid container>

      <PageTitle title="جزییات بار" />

      <Grid container>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12} >

          <label>مبدا</label>
          <Select
            onInputChange={cityOption3}
            onChange={e => {
              setNewContent({
                ...NewContent,
                sourceCityId: e.value,
              });

            }}
            options={city}
            value={cityOption3.value}
            placeholder="شهر مبدا را انتخاب کنید"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}

        >
          <label>مقصد</label>
          <Select
            onInputChange={cityOption3}
            onChange={e => {
              setNewContent({
                ...NewContent,
                destinationCityId: e.value,
              });

            }}
            options={city}
            value={cityOption3.value}
            placeholder="شهر مقصد را انتخاب کنید"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>عنوان بار</label>
          <Input
            value={NewContent.title}
            onChange={({ target }) =>
              setNewContent(state => ({
                ...state,
                title: target.value,
              }))
            }
            type="text"
          />
        </Grid>
        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>وزن </label>
          <Input
            value={NewContent.weight}
            onChange={({ target }) =>
              setNewContent(state => ({
                ...state,
                weight: target.value,
              }))
            }
            type="number"
          />
        </Grid>

        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>قیمت </label>
          <Input
            value={NewContent.requestPrice}
            onChange={({ target }) =>
              setNewContent(state => ({
                ...state,
                requestPrice: target.value,
              }))
            }
            type="number"
          />
        </Grid>

        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>بارگیر </label>
          <Select
            onChange={e => setNewContent({
              ...NewContent,
              vehicleClassId: e.value,
            })}
            options={carrier.truckType}
            value={options.value}
            placeholder="بارگیر را انتخاب کنید"
          />
        </Grid>

        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label> شماره همراه</label>
          <Input
            value={NewContent.senderPhoneNumber}
            onChange={({ target }) =>
              setNewContent(state => ({
                ...state,
                senderPhoneNumber: target.value,
              }))
            }
            type="number"
          />
        </Grid>

        <Grid
          className={classes.colItem}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>توضیحات </label>
          <Input
            value={NewContent.description}
            onChange={({ target }) =>
              setNewContent(state => ({
                ...state,
                description: target.value,
              }))
            }
            type="text"
          />
        </Grid>


      </Grid>



      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        container
        justifyContent="space-between"
        direction="row-reverse"
      >
        <Button
          variant="contained"
          style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
          onClick={addNewContent}

        >
          ثبت
        </Button>
      </Grid>
    </Grid>
  );
};
export default SocialMediaOrder;
