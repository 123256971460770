import React, { useCallback, useEffect, useState, useContext } from "react";
import { Button, Divider, Grid } from "@material-ui/core";
import Input from "../../../components/utils/Input";
import useStyles from "../../../components/styles/styles";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import Address from "./componentsWaiting/Address";
import DetailCargo from "./componentsWaiting/DetailCargo";
import Api from "../../../constant/Api";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest, putRequest } from "../../../constant/ApiService";
import { TYPECARGO, TRUCKTYPE } from "../../../constant/staticData";
import { MainContext } from "../../../context/MainContext";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { useHistory } from "react-router-dom";
import Select from "react-select";

const DetailWaiting = () => {
  const classes = useStyles();
  const { id } = useParams();
  let {
    detail,
    setDetail,
    detailAddress,
    setDetailAddress,
    detailCargo,
    carrier,
    handleCarrierType,
    handleTruckType,
    setCarrier,
    weightValue,
    setWeightValue,
    packageValue,
    setPackageValue,
    detailDisAddress,
    setDetailDisAddress,
    detailOriginAddress,
    setDetailOriginAddress,
  } = useContext(MainContext);
  const history = useHistory();
  const {
    name,
    source,
    distination,
    transfereeName,
    mobile,
    transfereePhoneNumber,
    address,
    priority,
    transfereeAddress,
    transfereePriority,
    postalCode,
    tellNumber,
    transfereePostalCode,
    transfereeTellNumber,
  } = detailAddress;

  const {
    cargo,
    length,
    height,
    width,
    number,
    volume,
    status,
    value,
    numCargo,
    desTopCargo,
    // weight,
    insurance,
  } = detailCargo;

  const [cargoLoadingType, setCargoLoadingType] = useState([]);
  const [selectedDay, setSelectedDay] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [time, setTime] = useState({ hour: 0, minute: 0 });
  const [prices, setPrices] = useState({
    price: "",
    driverPrice: "",
    basePrice: "",
  });
  const { price, driverPrice, basePrice } = prices;
  const [cargoType, setCargoType] = useState("");
  const [disabled, setDisabled] = useState(false);

  const [isCheckedSelect, setIsCheckedSelect] = useState([]);
  const handleChangeCargoType = e => setCargoType(e.value);

  useEffect(() => {
    handleCarrierType();
    handleTruckType();
  }, []);

  useEffect(() => {
    getRequest(Api.GetCargoDetail + "?Id=" + id).then(res => {
      setDetail(res && res.data);
    });
  }, [id]);

  const confirmCargoFunc = () => {
    if (mobile && !/^[0-9]{11}$/.test(mobile)) {
      toast.error("شماره همراه مبداء را درست وارد کنید");
      return;
    }
    if (transfereePhoneNumber && !/^[0-9]{11}$/.test(transfereePhoneNumber)) {
      toast.error("شماره همراه مقصد را درست وارد کنید");
      return;
    }
    if (transfereeTellNumber && !/^[0-9]{11}$/.test(transfereeTellNumber)) {
      toast.error("شماره تلفن مقصد را درست وارد کنید");
      return;
    }
    if (tellNumber && !/^[0-9]{11}$/.test(tellNumber)) {
      toast.error("شماره تلفن مبداء را درست وارد کنید");
      return;
    }
    if (postalCode && !/^[0-9]{10}$/.test(postalCode)) {
      toast.error("کدپستی مبداء را درست وارد کنید");
      return;
    }
    if (transfereePostalCode && !/^[0-9]{10}$/.test(transfereePostalCode)) {
      toast.error("کد پستی مقصد را درست وارد کنید");
      return;
    }
    if (!packageValue) {
      toast.error("نوع بسته بندی را انتخاب کنید");
      return;
    }
    if (!weightValue) {
      toast.error("وزن بار را انتخاب کنید");
      return;
    }
    if (!carrier.truckTypeValue) {
      toast.error("نوع ناوگان را انتخاب کنید");
      return;
    }
    if (!carrier.carrierTypeValue) {
      toast.error("نوع بارگیر را انتخاب کنید");
      return;
    }
    if (!isCheckedSelect) {
      toast.error("روباری را انتخاب کنید");
      return;
    }

    if (!value) {
      toast.error("بیمه  را وارد کنید");
      return;
    }
    if (!transfereeName) {
      toast.error("نام گیرنده را وارد کنید");
      return;
    }

    if (!basePrice) {
      toast.error("قیمت پایه را وارد کنید");
      return;
    }
    if (!price) {
      toast.error("قیمت را وارد کنید");
      return;
    }
    if (!driverPrice) {
      toast.error("قیمت راننده را وارد کنید");
      return;
    }
    if (!time.minute) {
      toast.error("دقیقه را وارد کنید");
      return;
    }
    if (!time.hour) {
      toast.error("ساعت را وارد کنید");
      return;
    }
    if (!time.hour) {
      toast.error("ساعت را وارد کنید");
      return;
    }
    if (!detailDisAddress) {
      toast.error("آدرس مقصد را وارد کنید");
      return;
    }
    if (!detailOriginAddress) {
      toast.error("آدرس مبداء را وارد کنید");
      return;
    }
  
    if (!selectedDay) {
      toast.error("تاریخ را وارد کنید");
      return;
    }
    let body = {
      id: detail.id,
      //sourceId: detail.source.id,
      //destId: detail.dest.id,
      packageTypeId: packageValue.value,
      truckTypeId: carrier.truckTypeValue.value,
      carrierTypeId:
        carrier.carrierTypeValue.value,
      cargoStateId: detail.cargoState.id,
      truckId: 0,
      title: cargo,
      weight: weightValue.value,
      vehicleCount: numCargo,
      description: desTopCargo,
      insuranceAmount: value,
      requestPrice: detail.requestPrice,
      transfereeName: transfereeName,
      transfereePhoneNumber: transfereePhoneNumber,
      day: selectedDay.day,
      month: selectedDay.month,
      year: selectedDay.year,
      hour: Number(time.hour),
      minute: time.minute,
      length: length == null ? 0 : length,
      width: width == null ? 0 : width,
      height: height == null ? 0 : height,
      number: number == null ? 0 : number,
      volume: volume == null ? 0 : volume,
      second: 0,
      status: status,
      cargoDischargeLocations: detailDisAddress,
      cargoLoadingLocations: detailOriginAddress,
      cargoLoadingTypes: isCheckedSelect && isCheckedSelect,
      transactionId: detail.transactionId,
      driverUserId: detail.driverUserId,
      driverUserName: detail.driverUserName,
      driverPhone: detail.driverPhone,
      wayBill: detail.wayBill,
      cargoPrice: {
        id: 0,
        realPrice: basePrice,
        cargoOwnerPrice: price,
        driverPrice: driverPrice,
      },
      SenderName: name,
      SenderPhoneNumber: mobile,

      loadingDateTime:
        selectedDay &&
        `${selectedDay.year}/${selectedDay.month}/${selectedDay.day}`,
    };

    console.log(body)
    putRequest(`${Api.GetConfirmCargo}/${detail.id}`, body).then(res => {
      if (res && res.succeeded && res.succeeded == true) {
        toast.success(res.message);
        history.push("/app/transactionsWaiting");
      }
    });
  };
  useEffect(() => {
    if (detail) {
      setSelectedDay({
        day: detail.day,
        month: detail.month,
        year: detail.year,
      });
      setTime({ ...time,hour: detail.hour, minute: detail.minute });

      //setTime({ ...time, hour: detail.hour });
      setPrices({
        ...prices,
        basePrice: detail.cargoPrice.realPrice,
        driverPrice: detail.cargoPrice.driverPrice,
        price: detail.cargoPrice.cargoOwnerPrice,
      });

      setCarrier({
        ...carrier,
        carrierTypeValue:
          carrier.carrierType.length > 0 &&
          carrier.carrierType.filter(x => x.value == detail.carrierType.id)[0],
        truckTypeValue:
          carrier.truckType.length > 0 &&
          carrier.truckType.filter(x => x.value == detail.truckType.id)[0],
      });
    }
  }, [detail]);

  const handleCargoTypeOptions = TYPECARGO.map(item => {
    return {
      value: item.id,
      label: item.title,
    };
  });

  return (
    <Grid>
      <PageTitle title={detail && detail.cargoState.title} />

      <h2>جزییات بار</h2>
      <Divider style={{ width: "100%", margin: "10px 0" }} />
      <Grid container>
        <Grid container alignItems="center" justifyContent="space-between">
          <h3>جزییات آدرس</h3>

          <Grid container alignItems="center" justifyContent="space-between">
            <h4>جزییات مبداء</h4>
          </Grid>

          <Address />
        </Grid>
        <Divider style={{ width: "100%", margin: "10px 0" }} />
        <Grid container>
          <h3>جزییات بار</h3>
          <DetailCargo
            cargoLoadingType={cargoLoadingType}
            setCargoLoadingType={setCargoLoadingType}
            isCheckedSelect={isCheckedSelect}
            setIsCheckedSelect={setIsCheckedSelect}
          />
        </Grid>
        <Divider style={{ width: "100%", margin: "10px 0" }} />
        <Grid container>
          <h3> بارگیر</h3>
          <Grid container>
            <Grid
              className={classes.colItem}
              item
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <label> بارگیر</label>
              <Select
                onChange={e => setCarrier({ ...carrier, truckTypeValue: e })}
                options={carrier.truckType}
                value={carrier.truckTypeValue}
                placeholder="بارگیر را انتخاب کنید"
              />
            </Grid>
            <Grid
              className={classes.colItem}
              item
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <label> عنوان بارگیر</label>
              <Select
                onChange={e => setCarrier({ ...carrier, carrierTypeValue: e })}
                options={carrier.carrierType}
                value={carrier.carrierTypeValue}
                placeholder="نوع بارگیر را انتخاب کنید"
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ width: "100%", margin: "10px 0" }} />
        <Grid container>
          <h3>تاریخ و ساعت بارگیری</h3>
          <Grid container>
            <Grid
              className={`${classes.colItem} ${classes.datePicker}`}
              item
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <label>تاریخ بارگیری</label>
              <DatePicker
                value={selectedDay}
                onChange={setSelectedDay}
                shouldHighlightWeekends
                locale="fa"
                add
                this
                style={{ zIndex: "1" }}
              />
            </Grid>
            <Grid
              className={classes.colItem}
              item
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <label>ساعت</label>
              <Input
                onChange={e => setTime({ ...time, hour: e.target.value })}
                value={time.hour}
                type="text"
              />
            </Grid>
            <Grid
              className={classes.colItem}
              item
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <label>دقیقه</label>
              <Input
                onChange={e => setTime({ ...time, minute: e.target.value })}
                value={time.minute}
                type="text"
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ width: "100%", margin: "10px 0" }} />
        <Grid container>
          <h3>کرایه</h3>
          <Grid container>
            <Grid
              className={classes.colItem}
              item
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <label>کرایه پایه</label>
              <Input
                onChange={({ target }) =>
                  setPrices(state => ({
                    ...state,
                    basePrice: target.value,
                  }))
                }
                value={basePrice}
                type="text"
              />
            </Grid>
            <Grid
              className={classes.colItem}
              item
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <label>کرایه کل</label>
              <Input
                onChange={({ target }) =>
                  setPrices(state => ({
                    ...state,
                    price: target.value,
                  }))
                }
                value={price}
                type="text"
              />
            </Grid>
            <Grid
              className={classes.colItem}
              item
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <label>کرایه راننده</label>
              <Input
                onChange={({ target }) =>
                  setPrices(state => ({
                    ...state,
                    driverPrice: target.value,
                  }))
                }
                value={driverPrice}
                type="text"
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ width: "100%", margin: "10px 0" }} />
        <Grid container>
          <h3>باربری مربوطه</h3>
          <Grid container>
            <Grid
              className={classes.colItem}
              item
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <label>باربری</label>
              <Select
                onChange={handleChangeCargoType}
                options={handleCargoTypeOptions}
                value={handleCargoTypeOptions[0]}
                placeholder="باربری را انتخاب کنید"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" direction="row-reverse">
          <Grid style={{ display: "flex" }}>
            <Button
              variant="contained"
              style={{ color: "#fff", backgroundColor: "#3CD4A0" }}
              onClick={confirmCargoFunc}
              disabled={disabled}
            >
              ثبت{" "}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailWaiting;
