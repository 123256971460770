import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from "@material-ui/core";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Api from "../../../constant/Api";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory } from "react-router-dom";
import { MainContext } from "../../../context/MainContext";
import { deleteRequest, getRequest } from "../../../constant/ApiService";
import { useParams } from "react-router-dom";
import { Pagination } from "@material-ui/lab";

const MotesaddiCargos = () => {
  const history = useHistory();
  const { id } = useParams();

  let {
    handleChangeRowsPerPage,
    handleChangePage,
    openPopup,
    setOpenPopup,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
  } = useContext(MainContext);
  const [items, setItems] = useState([]);
  const [itemId, setItemId] = useState();
  const [count, setCount] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, id) => {
    console.log(id)
    setItemId(id)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    GetTransactionsWaiting()
  }, []);

  const GetTransactionsWaiting = () => {
    getRequest(`${Api.GetTransactionsWaiting}?cargoRequestType=2&PageNumber=${page}&PageSize=${20}`).then(res => {
      setItems(res && res.data.lists);
      setCount(res && res.data.count)

    });
  }

  const handleChange = (event, value) => {
    setPage(value);
    GetTransactionsWaiting();
  };
  const deleteCargoFunc = () => {
    console.log(id)
    deleteRequest(`${Api.DeleteCargo}/${itemId}`).then(res => {
      handleClose()
      GetTransactionsWaiting()
      return toast.success(res.data.message);
     
    });
  };
  return (
    <Grid>
      <PageTitle title="بارهای متصدی" />
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>شناسه</TableCell>
              <TableCell>نام سفارش دهنده</TableCell>
              <TableCell>تلفن تماس</TableCell>
              <TableCell>عنوان بار</TableCell>
              <TableCell>جزییات بار</TableCell>
              <TableCell>تاریخ و ساعت بارگیری</TableCell>
              <TableCell>تاریخ و ساعت ثبت سفارش</TableCell>
              <TableCell>اقدام</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              items.length > 0 &&
              items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push(`/app/motesaddiCargoDetail/${item.id}`);
                    }}
                  >
                    {item.id}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push(`/app/motesaddiCargoDetail/${item.id}`);
                    }}
                  >
                    {item.cargoOwnerName}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push(`/app/motesaddiCargoDetail/${item.id}`);
                    }}
                  >
                    {item.cargoOwnerPhoneNumber}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push(`/app/motesaddiCargoDetail/${item.id}`);
                    }}
                  >
                    {item.title}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push(`/app/motesaddiCargoDetail/${item.id}`);
                    }}
                  >
                    {item.packageTypeName}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push(`/app/motesaddiCargoDetail/${item.id}`);
                    }}
                  >
                    {item.persianLoadingDateTime}
                  </TableCell>

                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push(`/app/motesaddiCargoDetail/${item.id}`);
                    }}
                  >
                    {item.persianCreated}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(e)=>handleClick(e,item.id)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          width: "20ch",
                        },
                      }}
                    >
                      <MenuItem selected="Pyxis" onClick={handleClose}>
                        <Button
                          style={{ width: "100%" }}
                          variant="contained"
                          color="primary"
                        >
                          پیگیری سفارش
                        </Button>
                      </MenuItem>
                      <MenuItem selected="Pyxis" onClick={handleClose}>
                        <Button
                          style={{ width: "100%" }}
                          variant="contained"
                          color="info"
                        >
                          نقشه
                        </Button>
                      </MenuItem>
                      <MenuItem selected="Pyxis" onClick={handleClose}>
                        <Button
                          style={{ width: "100%" }}
                          variant="contained"
                          color="secondary"
                          onClick={() => deleteCargoFunc()}
                        >
                          رد درخواست
                        </Button>
                      </MenuItem>
                      <MenuItem selected="Pyxis" onClick={handleClose}>
                        <Button
                          style={{ width: "100%" }}
                          variant="contained"
                          color="success"
                        >
                          پرداخت
                        </Button>
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
            count={items && items.length > 0 ? Math.ceil(count / 20) : 0}
            page={page}
            onChange={handleChange}
            color="primary"
            variant="outlined"
            shape="rounded"
            size="large"
            style={{ marginTop: "2vh" }}
       />
    </Grid>
  );
};

export default MotesaddiCargos;
