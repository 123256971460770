import React, { useEffect, useState } from "react";
import { Grid, TextareaAutosize } from "@material-ui/core";
import Input from "../../../components/utils/Input";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import Api from "../../../constant/Api";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest, putRequest } from "../../../constant/ApiService";
import Select from "react-select";
import { useHistory } from "react-router-dom";


const MotesaddiCargoDetail = () => {
  const { id } = useParams();
  const history = useHistory();
  // const [detail, setDetail] = useState({
  //   id: id,
  //   sourceCityId: 0,
  //   destinationCityId: 0,
  //   sourceAddress: "",
  //   destAddress: "",
  //   senderName: "",
  //   senderPhoneNumber: "",
  //   transfereeName: "",
  //   transfereePhoneNumber: "",
  //   vehicleClassId: 0,
  //   weight: 0,
  //   title: "",
  //   price: 0,
  //   requestPrice: 0,
  //   description: "",


  // });
  const [city, setCity] = useState([]);
  const [truckType, setTruckType] = useState([]);
  const [cargo, setCargo] = useState({
    id: id,
    sourceCityId: 0,
    destinationCityId: 0,
    sourceAddress: "",
    destAddress: "",
    senderName: "",
    senderPhoneNumber: "",
    transfereeName: "",
    transfereePhoneNumber: "",
    vehicleClassId: 0,
    weight: 0,
    title: "",
    price: 0,
    requestPrice: 0,
    description: "",


  });


  const cargoDetail = () => {
    getRequest(Api.GetMotesaddiCargo + "?Id=" + id).then(res => {
      setCargo(res && res.data);
    });
  };

  const updateDetail = () => {
    var body = {
      id: cargo.id,
      sourceCityId: cargo.sourceCityId,
      destinationCityId: cargo.destinationCityId,
      sourceAddress: cargo.sourceAddress,
      destAddress: cargo.destAddress,
      senderName: cargo.senderName,
      senderPhoneNumber: cargo.transfereeName,
      transfereeName: cargo.transfereeName,
      transfereePhoneNumber: cargo.transfereePhoneNumber,
      vehicleClassId: cargo.vehicleClassId,
      weight: cargo.weight,
      title: cargo.title,
      price: cargo.price,
      requestPrice: cargo.requestPrice,
      description: cargo.description,


    }
    putRequest(`${Api.UpdateMotesaddiCargo}`, body).then(res => {
        history.push(`/app/motesaddiCargos`);
      }
    );

  };

  const cityOption = item => {
    getRequest(`${Api.GetCity}?CityName=${item}`).then(res => {
      const handleOptions =
        res &&
        res.data.lists.map(item => {
          return {
            value: item.id,
            label: item.cityName,
          };
        });

      setCity(handleOptions);
    });
  };


  const truckTypesOption = () => {
    getRequest(Api.GetTruckTypes).then(res => {
      const handleOptions =
        res &&
        res.data.lists.map((item) => {
          return {
            value: item.id,
            label: item.title,
          };
        });

      setTruckType(handleOptions);
    })

  };

  useEffect(() => {
    cargoDetail()
    cityOption();
    truckTypesOption();
  }, []);


  return (
    <section className="w-full">
      <Grid container>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 5,
            "& .DatePicker": {
              zIndex: "unset !important",
            }
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label> مبدا</label>
          <Select
            onInputChange={cityOption}
            onChange={(e) => {
              setCargo({
                ...cargo,
                sourceCityId: e.value,
              });
            }}
            options={city}
            value={cityOption.value}
            placeholder={cargo?.sourceAddress}
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 5,
            "& .DatePicker": {
              zIndex: "unset !important",
            }
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>نام ونام خانوادگی فرستنده</label>
          <Input
            onChange={({ target }) =>
              setCargo({
                ...cargo,
                senderName: target.value,
              })
            }
            value={cargo?.senderName}
            type="text"
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 5,
            "& .DatePicker": {
              zIndex: "unset !important",
            }
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>شماره همراه فرستنده</label>
          <Input
            onChange={({ target }) =>
              setCargo({
                ...cargo,
                senderPhoneNumber: target.value,
              })
            }
            value={cargo?.senderPhoneNumber}
            type="text"
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 5,
            "& .DatePicker": {
              zIndex: "unset !important",
            }
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label> مقصد</label>
          <Select
            onInputChange={cityOption}
            onChange={(e) => {
              setCargo({
                ...cargo,
                destinationCityId: e.value,
              });
            }}
            options={city}
            value={cityOption.value}
            placeholder={cargo?.destAddress}
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 5,
            "& .DatePicker": {
              zIndex: "unset !important",
            }
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>نام ونام خانوادگی گیرنده</label>
          <Input
            onChange={({ target }) =>
              setCargo({
                ...cargo,
                transfereeName: target.value,
              })
            }
            value={cargo?.transfereeName}
            type="text"
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 5,
            "& .DatePicker": {
              zIndex: "unset !important",
            }
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>شماره همراه گیرنده</label>
          <Input
            onChange={({ target }) =>
              setCargo({
                ...cargo,
                transfereePhoneNumber: target.value,
              })
            }
            value={cargo?.transfereePhoneNumber}
            type="text"
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 5,
            "& .DatePicker": {
              zIndex: "unset !important",
            }
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>نوع بارگیر</label>
          <Select
            onInputChange={truckTypesOption}
            onChange={(e) => {
              setCargo({
                ...cargo,
                vehicleClassId: e.value,
              });
            }}
            options={truckType}
            value={truckTypesOption.value}
            placeholder={`${cargo?.truckType?.title}  ${cargo?.carrierType?.title}`}
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 5,
            "& .DatePicker": {
              zIndex: "unset !important",
            }
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>وزن محموله</label>
          <Input
            onChange={({ target }) =>
              setCargo({
                ...cargo,
                weight: target.value,
              })
            }
            value={cargo?.weight}
            type="number"

          />
        </Grid>

        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 5,
            "& .DatePicker": {
              zIndex: "unset !important",
            }
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>عنوان بار</label>
          <Input
            onChange={({ target }) =>
              setCargo({
                ...cargo,
                title: target.value,
              })
            }
            value={cargo?.title}
            type="text"
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 5,
            "& .DatePicker": {
              zIndex: "unset !important",
            }
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label> قیمت</label>
          <Input
            value={cargo?.price}
            type="number"
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 5,
            "& .DatePicker": {
              zIndex: "unset !important",
            }
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>قیمت پیشنهادی</label>
          <Input
            onChange={({ target }) =>
              setCargo({
                ...cargo,
                requestPrice: target.value,
              })
            }
            value={cargo?.requestPrice}
            type="number"
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 5,
            "& .DatePicker": {
              zIndex: "unset !important",
            }
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <label>توضیحات</label>
          <TextareaAutosize
            onChange={({ target }) =>
              setCargo({
                ...cargo,
                description: target.value,
              })
            }
            value={cargo?.description}
            style={{ border: "solid 1px #dcdcdc", height: 78 }}
            type="text"
          />
        </Grid>


      </Grid>
      <Grid className="m-6">
        <div className="mt-6">
          <button
            type="button"
            onClick={updateDetail}
            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            ویرایش
          </button>
        </div>
      </Grid>
    </section>
  );
};

export default MotesaddiCargoDetail;
